<template>
  <div class="loginVercodePage">
    <div class="heade float">
      更改密码
      <div class="back" @click="back">
        <i class="bg"></i>
        返回
      </div>
    </div>
    <div class="form">
      <div class="item">
        <h4 class="title">手机号/账号</h4>
        <div class="input-view float">
          <a-input
            v-model:value="mobile"
            placeholder="请输入手机号/账号"
          />
        </div>
      </div>
      <div class="item">
        <h4 class="title">登录密码</h4>
        <div class="input-view float">
          <a-input-password
            v-model:value="password"
            placeholder="请输入8-16个字符，包含字母和数字"
          />
        </div>
      </div>
      <div class="item">
        <h4 class="title">确认登录密码</h4>
        <div class="input-view float">
          <a-input-password
            v-model:value="confirmPassword"
            placeholder="请再次确认密码"
          />
        </div>
      </div>
      <div class="item">
        <h4 class="title">图形验证码</h4>
        <div class="input-view">
          <a-input-search
            v-model:value="imageCode"
            placeholder="请输入图形验证码"
            @search="changeCode"
          >
            <template #enterButton>
              <img :src="imageCodeUrl" alt="" />
            </template>
          </a-input-search>
        </div>
      </div>
      <div class="item">
        <h4 class="title">短信验证码</h4>
        <div class="input-view">
          <a-input-search
            v-model:value="smsCode"
            placeholder="请输入图形验证码"
            @search="handleSmsCode"
          >
            <template #enterButton>
              <span v-show="verShow">获取短信验证码</span>
              <span v-show="!verShow">{{ timer }}秒后重新获取</span>
            </template>
          </a-input-search>
        </div>
      </div>
      <a-button
        type="primary"
        :disabled="loginController"
        @click="login"
        :loading="loading"
        >登录</a-button
      >
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      imageCodeUrl: "",
      mobile: "",
      imageCode: "",
      smsCode: "",
      confirmPassword: "",
      password: "",
      verShow: true,
      timer: 60,
      loading: false,
    };
  },
  mounted() {
    this.changeCode();
  },
  methods: {
    ...mapActions({
      setToken: "user/setToken",
      setUser: "user/setUser",
    }),
    async login() {
      if (this.loginController) return;
      const hide = this.$message.loading("登录中..", 0);
      this.loading = true;
      try {
        let res = await this.$http.post(this.$interface.memberModifyPassword, {
          mobile: this.mobile,
          imageCode: this.imageCode,
          smsCode: this.smsCode,
          password: this.password,
          confirmPassword: this.confirmPassword,
        });
        this.$message.success("修改成功");
        // await this.setToken({
        //   token: res.data.data.authorization,
        //   remember: true,
        // });
        // await this.setUser({
        //   classInfo: res.data.data.classInfo,
        //   hostipalName: res.data.data.hostipalName,
        // });
        // this.goHome();
        this.$emit("changeType", 2);
      } catch (errr) {
      } finally {
        hide();
        this.loading = false;
      }
    },
    back() {
      this.$emit("changeType", 2);
    },
    goHome() {
      const query = this.$route.query;
      const path = query && query.from ? query.from : "/";
      this.$router.push("/").catch(() => {});
    },
    /* 获取短信验证码 */
    handleSmsCode() {
      if (!this.verShow) return;
      if (this.mobile == '' || this.imageCode == "") {
        this.$message.error("请填写完整");
        return;
      }
      this.postSendSms();
      
    },
    async postSendSms() {
      try {
        let res = await this.$http.post(
          this.$interface.memberModifyPasswordSendSms,
          {
            mobile: this.mobile,
            imageCode: this.imageCode,
          },
          { withCredentials: true }
          
        );
        if (res.data.code == 0) {
            this.verShow = false;
            var auth_timer = setInterval(() => {
              this.timer--;
              if (this.timer <= 0) {
                this.verShow = true;
                this.timer = 60;
                clearInterval(auth_timer);
              }
            }, 1000);
          }else{
            this.changeCode();
          }
      } catch (errr) {
        this.changeCode();
      }
    },
    /* 更换图形验证码 */
    async changeCode() {
      try {
        let res = await this.$http.get(this.$interface.loginImageCode, {
          withCredentials: true,
        });
        this.imageCodeUrl = res.data.data || "";
      } catch (err) {}
    },
  },
  computed: {
    loginController() {
      if (
        this.mobile &&
        this.imageCode &&
        this.smsCode &&
        this.mobile &&
        this.confirmPassword &&
        this.password &&
        this.password.length <= 16 &&
        this.password.length >= 8
      )
        return false;
      return true;
    },
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>